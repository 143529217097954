'use strict';

/** Verify required functions for the app are available */
function checkCompat() {
  const testElement = document.createElement('DIV');
  const requiredFeatures = [
    document.dispatchEvent,
    testElement.classList,
    testElement.closest,
    window.Audio,
    window.CustomEvent,
    window.requestAnimationFrame
  ];
  const j = requiredFeatures.length;
  var i = 0;

  for (; i < j; i += 1) {
    if (typeof requiredFeatures[i] === 'undefined') {
      return false;
    }
  }
  return true;
}

module.exports = checkCompat;
