'use strict';

/* Fetch data from Creek On Air API */

const events = require('../core/events');
const fetch = require('../helpers/fetch');

const onAirUrl = '/api/data/onair/now.json';

const tracks = {
  fetchOnAir: function fetchOnAir() {
    fetch.get(onAirUrl, { responseType: 'json' }, {
      success: function gotCurrentTrack(response) {
        document.dispatchEvent(new CustomEvent(events.dataOnAirInfo, {
          detail: response
        }));
      },
      failure: function currentTrackFailed(e) {
        /* eslint-disable no-console */
        console.warn(e);
        /* eslint-enable no-console */
      }
    });
  }
};

module.exports = tracks;
