'use strict';

const throttle = require('lodash.throttle');
var events = {};

/**
 * Throttle event dispatch (e.g. for audio timeupdate).
 * @param key key String Identifier for the throttle; control permutations of
 *        the event throttle (e.g. you may limit not by type of event, but by source
 *        object, as you please.)
 * @param event CustomEvent Event object to be passed to dispatchEvent if this call is executed.
 * @param target Element The DOM element from which the event should be triggered. (default window.document)
 * @param freq Int The frequency in milliseconds that the event should fire (default 250)
 */
function handleThrottledDispatch(key, event, target, freq) {
  if (!key || !event) {
    return;
  }

  if (typeof events[key] !== 'function') {
    events[key] = throttle(function throttledDispatchEvent(e, t) {
      (t || document).dispatchEvent(e);
    }, freq || 250);
  }

  events[key](event, target);
}

module.exports = handleThrottledDispatch;
