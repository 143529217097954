'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

var MusicPlayer = forgeUiComponent('MusicPlayer', {
  locked: 'is-locked',
  missingImage: 'is-missingImage',
  volumeFocused: 'is-volumeFocused'
}, function MusicPlayerConstructor(rootElement, opts, builder) {
  var self = this;
  var trackId;
  var volumeLimit = 11;
  var lastPageUrl;
  var didRecentlyStop = false;

  Object.defineProperty(this, 'trackId', {
    get: function getTrackId() { return trackId; },
    set: function setTrackId(newId) { trackId = newId; }
  });

  Object.defineProperty(this, 'volumeLimit', {
    get: function getVolumeLimit() { return volumeLimit; },
    set: function setVolumeLimit(updatedLimit) { volumeLimit = updatedLimit; }
  });

  Object.defineProperty(this, 'lastPageUrl', {
    get: function getLastPage() { return lastPageUrl; },
    set: function setLastPage(updatedUrl) { lastPageUrl = updatedUrl; }
  });

  builder.defineClassState('playing', 'is-playing', function validate(bool) {
    return !!bool;
  }, function afterSet(newValue) {
    /* To ensure we ignore delayed progress events, catch if playback ceases */
    if (newValue === false) {
      didRecentlyStop = true;
      setTimeout(function () {
        didRecentlyStop = false;
      }, 500);
    }
  });

  builder.defineAttributeProperty('playFocus', 'data-focus', function validate(target) {
    if (['page', 'archive', 'live'].includes(target)) {
      return target;
    } else {
      return 'live';
    }
  });

  // Listen for sub-component events
  // play button
  // // Dispatch WantsPlayback
  // // Dispatch WantsPause
  // volume slider
  // // Dispatch WantsVolume

  // Listen to audio subsystem events for updates
  // We then emit state events that sub-components like the
  // ProgressPlayButton listen to

  document.addEventListener(events.audioCued, function (e) {
    if (self.locked || !e.detail) { return; }

    self.trackId = e.detail.id;
  });

  document.addEventListener(events.audioSourceLoading, function (e) {
    if (self.trackId !== (e.detail && e.detail.id)) { return; }

    self.playFocus = e.detail.isStream ? 'live' : 'archive';

    self.element.dispatchEvent(new CustomEvent(events.uiMediaStateChange, {
      bubbles: true,
      detail: {
        mediaState: 'loading'
      }
    }));
  });

  document.addEventListener(events.audioSourcePlaying, function (e) {
    if (self.trackId !== (e.detail && e.detail.id)) { return; }

    self.playing = true;
    self.playFocus = e.detail.isStream ? 'live' : 'archive';

    self.element.dispatchEvent(new CustomEvent(events.uiMediaStateChange, {
      bubbles: true,
      detail: {
        mediaState: 'playing',
        duration: e.detail.duration || Infinity,
        progress: e.detail.duration ? e.detail.time || 0 : Infinity
      }
    }));
  });

  document.addEventListener(events.audioSourceTimeUpdate, function (e) {
    // Ignore buffered time updates after audio is stopped
    if (didRecentlyStop) {
      return;
    }

    if (self.trackId !== (e.detail && e.detail.id)) { return; }

    self.playing = true;
    self.playFocus = e.detail.isStream ? 'live' : 'archive';

    self.element.dispatchEvent(new CustomEvent(events.uiMediaStateChange, {
      bubbles: true,
      detail: {
        mediaState: 'progress',
        duration: e.detail.duration || Infinity,
        progress: e.detail.duration ? e.detail.time || 0 : Infinity
      }
    }));
  });

  document.addEventListener(events.audioSourcePaused, function (e) {
    if (self.trackId !== (e.detail && e.detail.id)) { return; }

    self.playing = false;

    self.element.dispatchEvent(new CustomEvent(events.uiMediaStateChange, {
      bubbles: true,
      detail: {
        mediaState: 'paused'
      }
    }));
  });

  document.addEventListener(events.audioSourceEnded, function (e) {
    if (self.trackId !== (e.detail && e.detail.id)) { return; }

    self.playing = false;
    self.playFocus = 'live';

    self.element.dispatchEvent(new CustomEvent(events.uiMediaStateChange, {
      bubbles: true,
      detail: {
        mediaState: 'paused'
      }
    }));
  });

  document.addEventListener(events.audioSourceResumed, function (e) {
    if (self.trackId !== (e.detail && e.detail.id)) { return; }

    self.playing = true;
    self.playFocus = e.detail.isStream ? 'live' : 'archive';

    self.element.dispatchEvent(new CustomEvent(events.uiMediaStateChange, {
      bubbles: true,
      detail: {
        mediaState: 'playing',
        duration: e.detail.duration || Infinity,
        time: e.detail.time || 0
      }
    }));
  });

  // Listen for navigation changes for page content
  // Toggle toggling to the page audio preview mode
  document.addEventListener(events.pageAudioMetadataUpdate, function (e) {
    var detail = e.detail && e.detail;
    if (!detail) {
      return;
    }
    self.possiblyShowPageTrack();
  });

  document.addEventListener(events.pageAudioMetadataCleared, function () {
    self.clearPageTrack();
  });

  // TODO: Not yet sure how to handle this event. Need to determine what happens
  //   after a track is `unstalled` in terms of how that would be reflected in UI
  // document.addEventListener(events.audioSourceStalled, function (e) {
  //   if (self.trackId !== (e.detail && e.detail.id)) { return; }

  //   self.element.dispatchEvent(new CustomEvent(events.uiMediaStateChange, {
  //     bubbles: true,
  //     detail: {
  //       mediaState: 'loading'
  //     }
  //   }));
  // });

  document.addEventListener(events.audioSourceVolumeChanged, function (e) {
    const newVolume = (e.detail && e.detail.newValue) || 0;
    const spinalTappedVolume = newVolume * self.volumeLimit;
    // Turn it up to (a percentage of) 11

    self.element.dispatchEvent(new CustomEvent(events.uiUpdateSliderValue, {
      bubbles: true,
      detail: { newValue: spinalTappedVolume }
    }));
  });

  self.element.addEventListener(events.uiSliderValueChanged, function (e) {
    const sliderValue = e.detail && e.detail.newValue;
    self.volumeLimit = (e.detail && e.detail.limit) || 11;

    if (sliderValue === false) { return; }

    self.element.dispatchEvent(new CustomEvent(events.audioWantsSetVolume, {
      bubbles: true,
      detail: { newValue: sliderValue / self.volumeLimit }
    }));
  });

  const volumeIcon = self.element.querySelector('.MusicPlayer-volumeLabel') || document.createElement('a');
  volumeIcon.addEventListener('click', function toggleVolume(e) {
    e.preventDefault();
    self.volumeFocused = !self.volumeFocused;
  });

  // Once we're initialized, ask if there's any page audio
  setTimeout(function () {
    self.dispatchEvent(events.uiWantsPageAudioMetadata);
  }, 10);
});

MusicPlayer.prototype.possiblyShowPageTrack = function possiblyShowPageTrack() {
  // If we're doing anything with an archive, playing or paused don't displace it
  // Otherwise, if we're playing a live-stream, don't replace it
  if (this.playFocus === 'archive' || this.playing) {
    return;
  }
  this.playFocus = 'page';
};

MusicPlayer.prototype.clearPageTrack = function clearPageTrack() {
  // Reset to 'live' if 'page' is the current state (this check prevents resetting
  // to live over other play modes)
  if (this.playFocus === 'page') {
    this.playFocus = 'live';
  }
};

module.exports = MusicPlayer;
