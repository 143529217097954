'use strict';

const events = require('../core/events');
const onAirApi = require('../data/creekonairapi');

// Compensate for delay via the streaming CDN
const STREAMING_DELAY = 40000; // 40 seconds

function NowPlaying() {
  var self = this;
  this.firstEvent = true;
  this.lastEmittedMetadata = undefined;

  document.addEventListener(events.dataOnAirInfo, function (e) {
    self.receiveOnAirInfo(e.detail);
  });
}

NowPlaying.prototype.receiveOnAirInfo = function receiveOnAirInfo(onAirData) {
  const metadata = this.assembleMetadata(onAirData);
  const metadataHash = this.metadataHash(metadata);

  // TODO: If the metadata matches, don't emit another event
  if (!metadataHash || metadataHash === this.lastEmittedMetadata) {
    return;
  }

  // On the first event, emit immediately so that data gets displayed. After that,
  // apply the streaming buffer compensation.
  const delay = this.firstEvent ? 1 : STREAMING_DELAY;
  this.firstEvent = false;

  window.setTimeout(function emitEventAfterDelay() {
    this.emitEvent(metadata);
  }.bind(this), delay);
};

NowPlaying.prototype.metadataHash = function metadataHash(data) {
  if (!data || data === {}) {
    return false;
  }
  return [data.title, data.show, data.image].join(':');
};

NowPlaying.prototype.assembleMetadata = function assembleMetadata(onAirData) {
  if (!onAirData.program) {
    return null;
  }

  if (!onAirData.title) {
    // Show only
    return {
      title: onAirData.program,
      attribution: onAirData.presenter,
      album: 'BFF.fm',
      label: null,
      link: onAirData.url,
      image: onAirData.program_image,
      show: onAirData.program,
      showUrl: onAirData.url,
      showImage: onAirData.program_image
    };
  }

  return {
    title: onAirData.title,
    attribution: onAirData.artist,
    album: onAirData.album,
    label: onAirData.label,
    link: onAirData.url,
    image: onAirData.image || onAirData.program_image,
    show: onAirData.program,
    showUrl: onAirData.url,
    showImage: onAirData.program_image
  };
};

NowPlaying.prototype.emitEvent = function emitEvent(metadata) {
  this.lastEmittedMetadata = this.metadataHash(metadata);
  document.dispatchEvent(new CustomEvent(events.dataNowPlayingInfo, { bubbles: false, detail: metadata }));
};

NowPlaying.prototype.interface = {
  fetchCurrent: function getNowPlaying() {
    onAirApi.fetchOnAir();
  }
};

module.exports = new NowPlaying().interface;
