'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const SEEK_ANIMATION_TIME = 1000;

var ProgressPlayButton = forgeUiComponent('ProgressPlayButton', {}, function ProgressPlayButtonConstructor(rootElement, opts, builder) {
  var self = this;
  var unsetSeekingTimeout;
  var lastPlaybackPosition = 0;

  const playButtonElement = this.element.querySelector('.ProgressPlayButton-playButton') || document.createElement('button');
  const pauseButtonElement = this.element.querySelector('.ProgressPlayButton-pauseButton') || document.createElement('button');

  builder.defineClassState('playing', 'is-playing', function validate(isPlaying) {
    if (isPlaying) {
      self.loading = false;
      self.streaming = false;
      self.paused = false;
    }
    return isPlaying;
  });

  builder.defineClassState('progressing', 'is-progressing');
  builder.defineClassState('seeking', 'is-seeking');

  function animateSeek() {
    self.seeking = true;
    clearTimeout(unsetSeekingTimeout);
    unsetSeekingTimeout = setTimeout(function () { self.seeking = false; }, SEEK_ANIMATION_TIME);
  }

  builder.defineClassState('streaming', 'is-streaming', function validate(isStreaming) {
    if (isStreaming) {
      self.loading = false;
      self.playing = false;
      self.paused = false;
      self.progressing = false;
    }
    return isStreaming;
  });

  builder.defineClassState('loading', 'is-loading', function validate(isLoading) {
    if (isLoading) {
      self.playing = false;
      self.streaming = false;
      self.paused = false;
      self.progressing = false;
    }
    return isLoading;
  });

  builder.defineClassState('paused', 'is-paused', function validate(isPaused) {
    if (isPaused) {
      self.playing = false;
      self.streaming = false;
      self.loading = false;
    }
    return isPaused;
  });

  builder.defineAttributeProperty('playbackPercentage', 'data-playback-percentage', function validate(percent) {
    const newValue = Number.isNaN(percent) ? 0 : Math.round(percent);

    if (Math.abs(newValue - lastPlaybackPosition) > 1) {
      animateSeek();
    }

    return newValue;
  }, function afterSet(newValue) {
    self.playbackHalf = newValue >= 50;
  });

  setInterval(function detectSkip() {
    lastPlaybackPosition = self.playbackPercentage;
  }, 2000);

  builder.defineAttributeProperty('playbackHalf', 'data-playback-half', function validate(bool) {
    return bool ? 'true' : 'false';
  });

  // Listen for UI events
  document.addEventListener(events.uiMediaStateChange, function (e) {
    // If state change for an ancestor of this component?
    if (!e.target.contains(self.element)) {
      return;
    }

    // What is the new state?
    if (!e.detail) {
      return;
    }

    switch (e.detail.mediaState) {
      case 'loading': self.loading = true; break;
      case 'paused': self.paused = true; break;
      case 'playing':
        if (e.detail.duration === Infinity) {
          self.streaming = true;
        } else {
          self.play(e.detail.duration, e.detail.progress);
        }
        break;
      case 'progress':
        self.progress(e.detail.duration, e.detail.progress);
        break;
      default:
        // TODO: Should we unset everything or ignore?
        self.loading = false;
        self.playing = false;
        self.streaming = false;
        self.paused = false;
        self.progressing = false;
        self.seeking = false;
    }
  });

  playButtonElement.addEventListener('click', function clickPlay() {
    self.loading = true;
    self.element.dispatchEvent(new CustomEvent(events.audioWantsPlayback, {
      bubbles: true
    }));
  });

  pauseButtonElement.addEventListener('click', function clickPause() {
    self.element.dispatchEvent(new CustomEvent(events.audioWantsPause, {
      bubbles: true
    }));
    self.paused = true;
  });
});

ProgressPlayButton.prototype.play = function play(recordingLength, startPos) {
  this.playing = true;
  this.progress(recordingLength, startPos);
};

ProgressPlayButton.prototype.progress = function play(recordingLength, startPos) {
  if (recordingLength === Infinity) {
    this.progressing = false;
  } else {
    this.progressing = true;
    this.playbackPercentage = (startPos / recordingLength) * 100;
  }
};

ProgressPlayButton.prototype.pause = function pause() {
  this.paused = true;
};

module.exports = ProgressPlayButton;
