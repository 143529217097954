'use strict';

const forgeUiComponent = require('../framework/ui-component');

var PlayerMenu = forgeUiComponent('PlayerMenu', {
  menuShowing: 'is-showingMenu'
}, function PlayerMenuConstructor() {
  var self = this;

  const button = this.element.querySelector('.PlayerMenu-button') || document.createElement('a');

  button.addEventListener('click', function (e) {
    self.menuShowing = !self.menuShowing;
    e.preventDefault();
  });

  document.addEventListener('click', function (e) {
    if (self.element === e.target || self.element.contains(e.target)) {
      return;
    }

    self.menuShowing = false;
  });
});

module.exports = PlayerMenu;
