'use strict';

function request(url, options, callbacks) {
  var xhr;
  var success = callbacks.success || function () {};
  var failure = callbacks.failure || function () {};
  var abort = callbacks.abort || callbacks.failure || function () {};
  var progress = callbacks.progress || function () {};

  if (!options.method) {
    failure();
    return false;
  }

  if (!window.XMLHttpRequest) {
    failure();
    return false;
  }

  xhr = new window.XMLHttpRequest();
  xhr.withCredentials = !!options.withCredentials;

  xhr.addEventListener('progress', function (e) {
    if (e.lengthComputable) {
      progress(e.loaded / e.total, xhr);
    } else {
      progress(-1, xhr);
    }
  });

  xhr.addEventListener('error', function (e) {
    failure(e, xhr);
  });
  xhr.addEventListener('abort', function (e) {
    abort(e, xhr);
  });
  xhr.addEventListener('load', function () {
    success(xhr.response, xhr);
  });

  xhr.open(options.method, url, true);

  if (options.headers) {
    Object.keys(options.headers).forEach(function (k) {
      xhr.setRequestHeader(k, options.headers[k]);
    });
  }

  // TODO: Make this explicit getHtml/getJson methods I think
  xhr.responseType = options.responseType || 'document';
  xhr.send();
  return xhr;
}

function get(url, options, callbacks) {
  var fullOptions = options;
  fullOptions.method = 'GET';
  return request(url, fullOptions, callbacks);
}

function head(url, options, callbacks) {
  var fullOptions = options;
  fullOptions.method = 'HEAD';
  return request(url, fullOptions, callbacks);
}

module.exports = {
  request: request,
  get: get,
  head: head
};
