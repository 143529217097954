'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const MAX_ATTRIBUTE = 'max';
const MIN_ATTRIBUTE = 'min';
const TIMESTAMP_REGEX = /^(?:(\d+)[h:])?(?:(\d+)[m:'′])?(\d+)(?:[s"″])?$/;

var AudioSkipButton = forgeUiComponent('AudioSkipButton', {}, function AudioSkipButtonConstructor() {
  var self = this;
  var currentTime = '0\'00"';

  this.element.addEventListener('click', function (e) {
    var skipValue;

    if (self.fixedConfiguration) {
      skipValue = self.skipAdjustment;
    } else {
      const currentMinutes = currentTime > 0 ? Math.floor(currentTime / 60) : 0;
      const prefilledStamp = currentMinutes + '\'' + Math.floor(currentTime - (currentMinutes * 60)) + '"';
      /* eslint-disable no-alert */
      skipValue = self.parseSkipAdjustment(prompt('Skip to…', prefilledStamp));
      /* eslint-enable no-alert */
    }

    if (skipValue === undefined) {
      return;
    }

    document.dispatchEvent(new CustomEvent(events.audioWantsSkip, {
      detail: skipValue
    }));

    e.preventDefault();
  });

  document.addEventListener(events.audioSourceTimeUpdate, function (e) {
    if (!e || !e.detail) {
      return;
    }

    if (e.detail.duration) {
      currentTime = e.detail.time;
    } else {
      currentTime = 0;
    }
  });

  Object.defineProperty(this, 'fixedConfiguration', {
    get: function hasConfigurationAttribute() {
      return !!this.element.getAttribute('data-skip');
    }
  });

  Object.defineProperty(this, 'skipAdjustment', {
    get: function getSkipAmount() {
      return this.parseSkipAdjustment(this.element.getAttribute('data-skip'));
    }
  });
});

AudioSkipButton.prototype.parseSkipAdjustment = function parseSkipAdjustment(input) {
  if (input === undefined) { return; }

  const configValue = '' + input;

  if (configValue.toLowerCase() === MAX_ATTRIBUTE) {
    return { relative: Infinity };
  }

  if (configValue.toLowerCase() === MIN_ATTRIBUTE) {
    return { relative: -Infinity };
  }

  if (configValue.indexOf('+') === 0 || configValue.indexOf('-') === 0) {
    return { relative: +configValue };
  }

  // If absolute seconds just return
  if (!Number.isNaN(+configValue)) {
    return { absolute: +configValue };
  }

  // Timestamp string
  // We'll allow hour:min:sec, min'sec'', min′sec″
  const timeMatch = configValue.match(TIMESTAMP_REGEX);

  if (timeMatch === null) { return; }

  const hours = +(timeMatch[1] || 0);
  const minutes = +(timeMatch[2] || 0);
  const seconds = +(timeMatch[3] || 0);
  const total = seconds + (minutes * 60) + (hours * 60 * 60);

  if (total <= 0) { return; }

  return { absolute: total };
};

module.exports = AudioSkipButton;
