'use strict';

const events = require('./events');
const FALLBACK_IMAGE = 'https://aw.bff.fm/assets/favicons/apple-touch-icon/670e7f66554fa4d50219e02bee256e233047dc1e.png';

/**
 * MediaSessionHandler provides an interface between the BFF audio metadata updates and the browser
 * navigator.mediaSession API for displaying track info in Android lock screen notifications.
 */
function MediaSessionHandler() {
  const self = this;
  var nowPlaying;

  Object.defineProperty(this, 'nowPlaying', {
    get: function () { return nowPlaying; },
    set: function (newSource) {
      if (newSource === nowPlaying) {
        return;
      }
      if (!newSource) {
        nowPlaying = false;
      } else {
        nowPlaying = newSource;
      }
      this.clear();
    }
  });

  if ('mediaSession' in window.navigator) {
    // Track what is currently playing using loading and timeupdate:
    document.addEventListener(events.audioSourceLoading, function (e) {
      self.nowPlaying = e.detail && e.detail.src;
    });

    document.addEventListener(events.audioSourceTimeUpdate, function (e) {
      self.nowPlaying = e.detail && e.detail.src;
    });

    // Receive metadata and filter for now playing:
    document.addEventListener(events.audioSourceMetadataUpdate, function (e) {
      const audioSource = e.detail && e.detail.src;
      if (audioSource !== nowPlaying) {
        return;
      }
      self.updateMetadata(e.detail.metadata);
    });
  }
}

MediaSessionHandler.prototype.updateMetadata = function updateMetadata(metadata) {
  if (!metadata.title || metadata.title === metadata.show) {
    // Show metadata
    navigator.mediaSession.metadata = new MediaMetadata({
      title: metadata.show,
      artist: metadata.artist,
      album: 'BFF.fm',
      artwork: [{ src: metadata.image || FALLBACK_IMAGE }]
    });
  } else {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: metadata.title,
      artist: metadata.artist,
      album: [metadata.show, 'on BFF.fm'].join(' '),
      artwork: [{ src: metadata.image || FALLBACK_IMAGE }]
    });
  }
};

MediaSessionHandler.prototype.clear = function clearMetadata() {
  navigator.mediaSession.metadata = new MediaMetadata({
    title: 'BFF.fm',
    artwork: [{ src: FALLBACK_IMAGE }]
  });
};

module.exports = MediaSessionHandler;
