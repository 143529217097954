'use strict';

/** Static Playback Metadata
  * Return static/predefined playback metadata for media files
  */
const events = require('../core/events');

function StaticPlaybackMetadata(mp3url, metadata) {
  var self = this;

  if (!metadata) {
    return;
  }

  Object.defineProperty(this, 'metadata', {
    get: function () { return metadata; }
  });

  Object.defineProperty(this, 'src', {
    get: function () { return mp3url; }
  });

  document.addEventListener(events.audioSourceTimeUpdate, function (e) {
    const audioSrc = e.detail && e.detail.src;

    // If the audio src is unchanged since the last update, discard since metadata is static
    if (this.lastSrc === audioSrc) { return; }

    // If the audio src is not this MP3, disregard the event
    if (audioSrc !== mp3url) { return; }

    self.handleTrackProgress();
    this.lastSrc = audioSrc;
  });
}

StaticPlaybackMetadata.prototype.handleTrackProgress = function handleTrackProgress() {
  const metadataUpdate = {
    src: this.src,
    metadata: {
      title: this.metadata.title,
      artist: this.metadata.artist,
      image: this.metadata.image,
      show: this.metadata.show,
      showUrl: this.metadata.showUrl,
      showImage: this.metadata.image,
      isStream: false
    }
  };

  document.dispatchEvent(new CustomEvent(
    events.audioSourceMetadataUpdate,
    { bubbles: true, detail: metadataUpdate }
  ));
};

module.exports = StaticPlaybackMetadata;
