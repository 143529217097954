'use strict';

const bffConfig = require('../config/bff');
const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');
const BroadcastMetadata = require('../core/broadcastmetadata');
const StaticPlaybackMetadata = require('../core/static-playback-metadata');

/* Common underpinning component for simple playback buttons and links, handles
 * common state clases. Strictly non-visual on its own. */
var PlaybackInvoker = forgeUiComponent('PlaybackInvoker', {}, function PlaybackInvokerConstructor(element, opts, builder) {
  var self = this;
  var metadataHandler;
  var didRecentlyStop = false;

  builder.defineClassState('loading', 'is-loading');
  builder.defineClassState('playing', 'is-playing', null, function afterSet(newValue) {
    /* To ensure we ignore delayed progress events, catch if playback ceases */
    if (newValue === false) {
      didRecentlyStop = true;
      setTimeout(function () {
        didRecentlyStop = false;
      }, 1000);
    }
  });

  Object.defineProperty(this, 'src', {
    get: function getSrc() {
      // Support base64 encoded src by default
      var src = this.element.getAttribute('data-src');
      if (src) {
        try {
          src = atob(src);
        } catch (e) {
          // Cannot decode base64
        }
        return src;
      } else {
        return this.element.href;
      }
    }
  });

  Object.defineProperty(this, 'broadcast', {
    get: function getTrackId() {
      return this.element.getAttribute('data-broadcast');
    }
  });

  Object.defineProperty(this, 'caption', {
    get: function getPlayerCaption() {
      return this.element.getAttribute('data-caption');
    }
  });

  Object.defineProperty(this, 'artist', {
    get: function getPlayerCaption() {
      return this.element.getAttribute('data-artist');
    }
  });

  Object.defineProperty(this, 'show', {
    get: function getPlayerShowName() {
      return this.element.getAttribute('data-show');
    }
  });

  Object.defineProperty(this, 'showUrl', {
    get: function getPlayerShowUrl() {
      return this.element.getAttribute('data-showUrl');
    }
  });

  Object.defineProperty(this, 'canSeek', {
    get: function getSeekable() {
      return !!this.element.getAttribute('data-seekable');
    }
  });

  Object.defineProperty(this, 'imageUrl', {
    get: function getPlayerMediaImage() {
      return this.element.getAttribute('data-image');
    }
  });

  Object.defineProperty(this, 'isStream', {
    get: function getStreamAttribute() {
      return !!this.element.getAttribute('data-stream');
    }
  });

  element.addEventListener('click', function (e) {
    e.preventDefault();

    if (self.playing) {
      document.dispatchEvent(new CustomEvent(events.audioWantsPause));
      return;
    }

    const playbackEventPayload = {
      url: self.isStream ? bffConfig.liveStreamUrl : self.src,
      seekable: self.canSeek,
      metadata: {} // TODO: Get core/audio out of the metadata business
    };

    document.dispatchEvent(new CustomEvent(events.audioWantsPlayback, {
      detail: playbackEventPayload
    }));

    if (!metadataHandler && self.broadcast) {
      metadataHandler = new BroadcastMetadata(self.src, self.broadcast);
    }

    if (!metadataHandler && self.caption) {
      metadataHandler = new StaticPlaybackMetadata(self.src, {
        title: self.caption,
        artist: self.artist,
        show: self.show,
        showUrl: self.showUrl,
        image: self.imageUrl
      });
    }
  });

  document.addEventListener(events.audioSourceLoading, function handleLoading(e) {
    self.playing = false;
    self.loading = self.eventMatchesPlayer(e);
  });

  document.addEventListener(events.audioSourcePlaying, function handlePlaying(e) {
    self.loading = false;
    self.playing = self.eventMatchesPlayer(e);
  });

  /* By subscribing to TimeUpdate as well as playing, we will update state on
   * content loaded after audio is already playing */
  document.addEventListener(events.audioSourceTimeUpdate, function handlePlaying(e) {
    /* An update event can still be triggered after the pause/end event so we track it */
    if (didRecentlyStop) {
      return;
    }
    self.loading = false;
    self.playing = self.eventMatchesPlayer(e);
  });

  document.addEventListener(events.audioSourceResumed, function handleResume(e) {
    self.playing = self.eventMatchesPlayer(e);
    self.loading = false;
  });

  document.addEventListener(events.audioSourcePaused, function handlePause() {
    self.playing = false;
    self.loading = false;
  });

  document.addEventListener(events.audioSourceEnded, function handleEnded() {
    self.playing = false;
    self.loading = false;
  });
});

PlaybackInvoker.prototype.eventMatchesPlayer = function eventMatchesPlayer(event) {
  return event.detail && ((event.detail.isStream === true && this.isStream === true) || event.detail.src === this.src);
};

module.exports = PlaybackInvoker;
