'use strict';

/** Broadcast Metadata
  * Pull broadcast data from the API and emit metadata updates when appropriate
  */
const events = require('../core/events');
const broadcastApi = require('../data/creekbroadcastapi');

function BroadcastMetadata(mp3url, broadcastId) {
  var self = this;

  if (!broadcastId) {
    return;
  }

  Object.defineProperty(this, 'id', {
    get: function () { return broadcastId; }
  });

  Object.defineProperty(this, 'src', {
    get: function () { return mp3url; }
  });

  this.playlist = [];

  document.addEventListener(events.dataBroadcastInfo, function (e) {
    self.parseBroadcast(e.detail);
  });

  document.addEventListener(events.audioSourceTimeUpdate, function (e) {
    const audioSrc = e.detail && e.detail.src;

    if (audioSrc !== mp3url) { return; }

    // TODO: Probably throttle this
    self.handleTrackProgress(e.detail.time);
  });

  if (broadcastId) {
    broadcastApi.fetch(broadcastId);
  }
}

// Handle data — store show core data
// Structure tracks with normalized timestamps for file duration
BroadcastMetadata.prototype.parseBroadcast = function parseBroadcast(data) {
  var self = this;
  this.title = data.title;
  this.showName = data.Show.title;
  this.showUrl = data.url; // Link to the broadcast page
  this.showImageUrl = data.Show.Image.url_md;

  const showStartTime = this.makeTimestamp(data.start);

  this.playlist = data.tracks.map(function mapTrack(track) {
    return {
      id: track.id,
      start: self.makeTimestamp(track.played) - showStartTime,
      // Guard against < 0 end times
      end: Math.max(0, track.ended ? self.makeTimestamp(track.ended) - showStartTime : Infinity) || Infinity,
      artist: track.artist,
      title: track.title,
      album: track.album,
      label: track.label
    };
  });

  // Trigger an initial update as the data has arrived
  this.handleTrackProgress(0);
};

BroadcastMetadata.prototype.makeTimestamp = function makeTimestamp(creekDate) {
  return Date.parse(creekDate.split(' ').join('T')) / 1000;
};

BroadcastMetadata.prototype.trackForTime = function trackForTime(ms) {
  return this.playlist.filter(function (track) {
    return track.start <= ms && track.end > ms;
  }).pop();
};

BroadcastMetadata.prototype.handleTrackProgress = function handleTrackProgress(time) {
  // Do nothing if there's no data yet
  if (!this.title) { return; }

  const currentTrack = this.trackForTime(time) || { id: -1 };

  if (this.lastNotifiedTrack === currentTrack.id) { return; }

  const metadataUpdate = {
    src: this.src,
    metadata: {
      title: currentTrack.title || this.title,
      artist: currentTrack.artist,
      image: this.showImageUrl,
      show: this.showName,
      showUrl: this.showUrl,
      showImage: this.showImageUrl,
      isStream: false
    }
  };

  document.dispatchEvent(new CustomEvent(
    events.audioSourceMetadataUpdate,
    { bubbles: true, detail: metadataUpdate }
  ));

  this.lastNotifiedTrack = currentTrack.id;
};

module.exports = BroadcastMetadata;
