'use strict';

const ComponentObjectHelper = require('./component-object-helper');

/**
  * A builder for creating UI Components with className states and discoverability
  */
module.exports = function forgeUiComponent(className, states, constructFn) {
  if (typeof className !== 'string') {
    throw new TypeError('Component requires a root className');
  }

  constructFn = constructFn || function () {};
  states = states || {};

  if (typeof constructFn !== 'function') {
    throw new TypeError('Component constructor must be a function');
  }

  if (typeof states !== 'object') {
    throw new TypeError('Component states must be an object');
  }

  // Create a constructor for the component we're defining
  function UiComponent(rootElement, options) {
    var self = this;

    if (!rootElement || rootElement.nodeType !== Node.ELEMENT_NODE) {
      throw new TypeError('Component requires an HTMLElement root');
    }

    options = options || {};

    if (typeof options !== 'object') {
      throw new TypeError('Component options must be an object');
    }

    const initAttribute = UiComponent.initAttribute();
    if (rootElement.getAttribute(initAttribute) !== null) {
      throw new Error('Component already initialized');
    } else {
      rootElement.setAttribute(initAttribute, +new Date());
    }

    const componentBuilder = new ComponentObjectHelper(this);

    Object.defineProperty(this, 'element', {
      get: function getRootElement() { return rootElement; }
    });

    Object.defineProperty(this, 'options', {
      get: function getOptions() { return options; }
    });

    Object.keys(states).forEach(function (stateProp) {
      componentBuilder.defineClassState(stateProp, states[stateProp]);
    });

    constructFn.apply(self, [rootElement, options, componentBuilder]);
  }

  UiComponent.prototype.queryProperty = function queryProperty(propName, returnUndefinedIfAbsent) {
    const selector = ['.', className, '-', propName].join('');
    const element = this.element.querySelector(selector);

    if (element) {
      return element;
    } else if (returnUndefinedIfAbsent) {
      return undefined;
    } else {
      return this.element.ownerDocument.createElement('ui-component-property');
    }
  };

  UiComponent.prototype.queryPropertyAll = function queryProperty(propName) {
    const selector = ['.', className, '-', propName].join('');
    return this.element.querySelectorAll(selector);
  };

  UiComponent.prototype.queryPropertyAttribute = function queryProperty(propName, attributeName) {
    const element = this.queryProperty(propName, true);
    if (element) {
      return element.getAttribute(attributeName);
    }
    return null;
  };

  /**
   *
   * @param {String} name The event name
   * @param {Object} detail The CustomEvent detail payload
   * @param {String|Event|HTMLElement} sourceProperty The source of the event (default: component root element)
   * @param {Boolean} bubbles Whether the event bubbles (default: true)
   */
  UiComponent.prototype.dispatchEvent = function dispatchComponentEvent(name, detail, sourceProperty, bubbles) {
    var sourceElement;
    const eventData = {
      detail: detail,
      bubbles: bubbles === undefined ? true : bubbles
    };
    if (!sourceProperty) {
      sourceElement = this.element;
    } else if (sourceProperty instanceof Event) {
      sourceElement = sourceProperty.target;
    } else if (sourceProperty instanceof HTMLElement) {
      sourceElement = sourceProperty;
    } else {
      sourceElement = this.queryProperty(sourceProperty);
    }
    if (!sourceElement) {
      return;
    }
    sourceElement.dispatchEvent(new CustomEvent(name, eventData));
  };

  UiComponent.initAttribute = function initAttribute() {
    return ['data', 'component', className.toLowerCase(), 'initialized'].join('-');
  };

  UiComponent.discover = function discoverComponent(rootElement, options) {
    var components = [];
    var errors = [];

    rootElement = rootElement || document.body;
    options = options || {};

    if (!rootElement || rootElement.nodeType !== Node.ELEMENT_NODE) {
      throw new TypeError('Components can only be discovered inside of DOM Elements');
    }

    if (typeof options !== 'object') {
      throw new TypeError('Component options must be an object');
    }

    if (rootElement.classList.contains(className)) {
      try {
        components += new UiComponent(rootElement, options);
      } catch (e) {
        errors += e;
      }
    } else {
      Array.prototype.forEach.call(rootElement.getElementsByClassName(className), function (discoveredElement) {
        try {
          components += new UiComponent(discoveredElement, options);
        } catch (e) {
          errors += e;
        }
      });
    }

    return {
      components: components,
      errors: errors
    };
  };

  return UiComponent;
};
