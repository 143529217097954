'use strict';

var compatibility = require('./helpers/compatibility');

var events = require('./core/events');
var domReady = require('./helpers/domready');

var Jukebox = require('./core/jukebox');
var Autoplay = require('./core/autoplay');
var LiveStream = require('./core/livestream');
var BrowserMediaSession = require('./core/browser-media-session');

var MusicPlayer = require('./ui/musicplayer');
var ProgressPlayButton = require('./ui/progressplaybutton');
var Slider = require('./ui/slider');
var PlayerMenu = require('./ui/playermenu');
var PlaybackInvoker = require('./ui/playbackinvoker');
var AudioSkipButton = require('./ui/audioskipbutton');
var AdvancedPlaybackControls = require('./ui/advancedplaybackcontrols');

// Init Components
function initGlobalComponents() {
  const body = document.body;
  Slider.discover(body);
  ProgressPlayButton.discover(body);
  MusicPlayer.discover(body);
  PlayerMenu.discover(body);
  AdvancedPlaybackControls.discover(body);

  // Init Audio
  new Jukebox();

  // Enable continuous playback
  new Autoplay();

  // Enable Media Session support on Android
  new BrowserMediaSession();

  // Cue up live tracking:
  new LiveStream('cue');
}

function initContentComponents() {
  var body = document.body;
  PlaybackInvoker.discover(body);
  AudioSkipButton.discover(body);
}

// Don't initialize app if browsing is missing features
if (compatibility()) {
  // TODO: Add “discover” to UI components.
  domReady(function () {
    initGlobalComponents();
    initContentComponents();
  });

  document.addEventListener(events.navigationRequestEnded, function (e) {
    if (e.detail.didNavigate) {
      initContentComponents();
    }
  });
}
