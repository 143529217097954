'use strict';

// registry of all custom events used in the app. Not ideal abstraction but good
// for sanity without going and reinventing interfaces.
const eventRegistry = {};

eventRegistry.dataOnAirInfo = 1;
eventRegistry.dataNowPlayingInfo = 1;
eventRegistry.dataBroadcastInfo = 1;
eventRegistry.dataHtmlPage = 1;

eventRegistry.navigationRequestStarted = 1;
eventRegistry.navigationRequestProgress = 1;
eventRegistry.navigationRequestEnded = 1;

eventRegistry.uiMediaStateChange = 1;
eventRegistry.uiMainMenuToggled = 1;
eventRegistry.uiScrollSnap = 1;
eventRegistry.uiSliderValueChanged = 1;
eventRegistry.uiUpdateSliderValue = 1;
eventRegistry.uiDidSearch = 1;
eventRegistry.uiModalDismiss = 1;
eventRegistry.uiWantsDonation = 1;
eventRegistry.uiPresentDonationCampaign = 1;
eventRegistry.uiActivateTab = 1;
eventRegistry.uiTabActivated = 1;
eventRegistry.uiWantsPageAudioMetadata = 1;
eventRegistry.uiRegionRefreshLoading = 1;
eventRegistry.uiRegionRefreshed = 1;

// Audio manager events (controls what's going to play)
eventRegistry.audioWantsPlayback = 1;
eventRegistry.audioWantsSkip = 1;
eventRegistry.audioWantsPause = 1;
eventRegistry.audioWantsStop = 1;
eventRegistry.audioWantsSetVolume = 1;
eventRegistry.audioNothingToPlay = 1;
eventRegistry.audioCueSource = 1;
eventRegistry.audioClearSource = 1;
eventRegistry.audioCued = 1;
eventRegistry.audioFormatNotSupported = 1;

// Audio source (MP3 stream or archive) events
eventRegistry.audioSourceLoading = 1;
eventRegistry.audioSourcePlaying = 1;
eventRegistry.audioSourcePaused = 1;
eventRegistry.audioSourceResumed = 1;
eventRegistry.audioSourceSeekOutOfRange = 1;
eventRegistry.audioSourceStalled = 1;
eventRegistry.audioSourceEnded = 1;
eventRegistry.audioSourceTimeUpdate = 1;
eventRegistry.audioSourceMetadataUpdate = 1;
eventRegistry.audioSourceVolumeChanged = 1;

// Audio metadata defined in pages
eventRegistry.pageAudioMetadataUpdate = 1;
eventRegistry.pageAudioMetadataCleared = 1;

eventRegistry.streamPlay = 1;

eventRegistry.themeChange = 1;
eventRegistry.themeWasChanged = 1;

eventRegistry.donationEnded = 1;
eventRegistry.donationStarted = 1;
eventRegistry.donationSuccess = 1;

// Set value == key in the registry
Object.keys(eventRegistry).forEach(function (evName) {
  eventRegistry[evName] = evName;
});

module.exports = eventRegistry;
