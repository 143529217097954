'use strict';

/* Fetch data from Creek /tracks API */

const events = require('../core/events');
const fetch = require('../helpers/fetch');

const broadcastUrlBase = '/api/broadcast/';

// Broadcasts never change when re-run so we cache the responses;
var broadcastCache = {};

const broadcasts = {
  fetch: function fetchBroadcast(id) {
    if (Object.prototype.hasOwnProperty.call(broadcastCache, id)) {
      document.dispatchEvent(new CustomEvent(events.dataBroadcastInfo, {
        detail: broadcastCache[id]
      }));
      return;
    }

    const broadcastUrl = broadcastUrlBase + id;
    fetch.get(broadcastUrl, { responseType: 'json' }, {
      success: function gotShow(response) {
        broadcastCache[id] = response;
        document.dispatchEvent(new CustomEvent(events.dataBroadcastInfo, {
          detail: response
        }));
      },
      failure: function currentTrackFailed(e) {
        /* eslint-disable no-console */
        console.warn(e);
        /* eslint-enable no-console */
      }
    });
  }
};

module.exports = broadcasts;
