'use strict';

/** Autoplay
  * Listen for 'nothingToPlay' and 'playbackEnded' events, trigger stream playback
  */
const events = require('../core/events');

function Autoplay() {
  document.addEventListener(events.audioNothingToPlay, function () {
    document.dispatchEvent(new CustomEvent(events.streamPlay));
  });

  document.addEventListener(events.audioSourceEnded, function () {
    document.dispatchEvent(new CustomEvent(events.streamPlay));
  });
}

module.exports = Autoplay;
