'use strict';

/* The jukebox. Controls what's playing */

const events = require('../core/events');
const AudioSource = require('../core/audio');

function Jukebox() {
  var self = this;
  var onDeck;
  var maintainedVolume = 1.0;

  Object.defineProperty(this, 'currentAudioSource', {
    get: function getCurrentAudioSource() { return onDeck; },
    set: function setAudioSource(audio) {
      if (onDeck === audio) { return; }

      if (onDeck) {
        // TODO: Fade Out
        onDeck.pollingForMetadata = false;
        onDeck.pause();
        // TODO: Does AudioSource need a `destroy`?
      }

      onDeck = audio;

      if (audio) {
        document.dispatchEvent(new CustomEvent(events.audioCued, {
          detail: {
            id: onDeck.id,
            src: onDeck.src
          }
        }));
      } else {
        document.dispatchEvent(new CustomEvent(events.audioClearSource));
      }
    }
  });

  Object.defineProperty(this, 'maintainedVolume', {
    get: function getMaintainedVolume() { return maintainedVolume; }
  });

  document.addEventListener(events.audioCueSource, function (e) {
    const requestedUrl = e.detail && e.detail;

    // Cue (but don't immediately play) new source
    if (requestedUrl) {
      self.cue(e.detail);
    }
  });

  document.addEventListener(events.audioWantsPlayback, function (e) {
    const requestedUrl = e.detail && e.detail.url;

    // Play new source
    if (requestedUrl && (!self.currentAudioSource || self.currentAudioSource.src !== requestedUrl)) {
      self.cue(e.detail);
    }

    // Resume existing source
    if (self.currentAudioSource) {
      self.currentAudioSource.play();
      return;
    }

    // Have no source
    document.dispatchEvent(new CustomEvent(events.audioNothingToPlay));
  });

  document.addEventListener(events.audioWantsPause, function () {
    if (self.currentAudioSource) {
      self.currentAudioSource.pause();
    }
  });

  document.addEventListener(events.audioWantsSkip, function (e) {
    if (self.currentAudioSource && e.detail) {
      if (e.detail.relative) {
        self.currentAudioSource.skip(e.detail.relative);
        return;
      }
      if (e.detail.absolute) {
        self.currentAudioSource.seek(e.detail.absolute);
      }
    }
  });

  document.addEventListener(events.audioSourceVolumeChanged, function (e) {
    maintainedVolume = (e.detail && e.detail.newValue) || maintainedVolume;
  });

  document.addEventListener(events.audioWantsSetVolume, function (e) {
    if (self.currentAudioSource && e.detail) {
      maintainedVolume = e.detail.newValue;
      self.currentAudioSource.volume = maintainedVolume;
    }
  });
}

Jukebox.prototype.cue = function cue(trackData) {
  const url = trackData.url || false;
  const seekable = trackData.seekable || false;

  // TODO: Validate audio info
  this.currentAudioSource = new AudioSource(url, this.maintainedVolume, seekable);
};

module.exports = Jukebox;
