'use strict';

/** Stream */
const events = require('../core/events');
const nowPlaying = require('../data/nowPlaying');
const bffConfig = require('../config/bff');

const METADATA_REFRESH_RATE = (60 / 4) * 1000;

function LiveStreamProvider(cue) {
  const self = this;
  const streamEventPayload = {
    url: bffConfig.liveStreamUrl,
    metadata: {}
  };

  document.addEventListener(events.streamPlay, function () {
    document.dispatchEvent(new CustomEvent(events.audioWantsPlayback, {
      detail: streamEventPayload
    }));
  });

  if (cue) {
    document.dispatchEvent(new CustomEvent(events.audioCueSource, {
      detail: streamEventPayload
    }));
  }

  document.addEventListener(events.dataNowPlayingInfo, function (e) {
    self.handleMetadata(e.detail);
  });

  document.addEventListener(events.audioFormatNotSupported, function () {
    // TODO: Redirect to “How to Listen” page
  });

  this.refreshMetadata();
  setInterval(function metadataInterval() {
    self.refreshMetadata();
  }, METADATA_REFRESH_RATE);
}

LiveStreamProvider.prototype.refreshMetadata = function refreshMetadata() {
  nowPlaying.fetchCurrent();
};

LiveStreamProvider.prototype.handleMetadata = function handleMetadata(metadata) {
  // Do nothing if there's no data
  if (!metadata || !metadata.title) { return; }

  const metadataUpdate = {
    src: bffConfig.liveStreamUrl,
    metadata: {
      title: metadata.title,
      artist: metadata.attribution,
      image: metadata.image,
      show: metadata.show,
      showUrl: metadata.showUrl,
      showImage: metadata.showImage,
      isStream: true
    }
  };

  document.dispatchEvent(new CustomEvent(
    events.audioSourceMetadataUpdate,
    { bubbles: true, detail: metadataUpdate }
  ));
};

module.exports = LiveStreamProvider;
