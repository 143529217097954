'use strict';

/* Slider */
const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

var Slider = forgeUiComponent('Slider', {
  disabled: 'is-disabled'
}, function SliderConstructor() {
  var self = this;

  var fillElement = this.element.querySelector('.Slider-fill') || document.createElement('div');
  var upButton = this.element.querySelector('.Slider-up') || document.createElement('button');
  var downButton = this.element.querySelector('.Slider-down') || document.createElement('button');

  Object.defineProperty(this, 'value', {
    get: function getValue() { return +this.element.getAttribute('data-value') || 0; },
    set: function setValue(v) {
      var newValue = Math.max(0, Math.min(v, this.limit));

      if (newValue === this.value) { return; }

      this.element.setAttribute('data-value', newValue);
      this.element.dispatchEvent(new CustomEvent(events.uiSliderValueChanged, {
        bubbles: true, detail: { newValue: newValue }
      }));
    }
  });

  Object.defineProperty(this, 'limit', {
    get: function getLimit() { return +this.element.getAttribute('data-limit') || 10; },
    set: function setLimit(v) { this.element.setAttribute('data-limit', v); }
  });

  Object.defineProperty(this, 'fillElement', {
    get: function getLimit() { return fillElement; }
  });

  upButton.addEventListener('click', function () {
    self.value += 1;
  });

  downButton.addEventListener('click', function () {
    self.value -= 1;
  });

  self.element.addEventListener(events.uiSliderValueChanged, function () {
    self.recalculateFill();
  });

  // Listen for UI events
  // TODO: Swapping to a generic `updateComponentValue` with a `tag` for the component
  //   could be a useful way to allow for subcomponent updates.
  document.addEventListener(events.uiUpdateSliderValue, function (e) {
    // If state change for an ancestor of this component?
    if (!e.target.contains(self.element)) {
      return;
    }

    // What is the new state?
    if (!e.detail) { return; }

    self.value = e.detail.newValue;
  });

  this.recalculateFill();

  // Emit a volume set event for whatever the slider was first set to
  this.element.dispatchEvent(new CustomEvent(events.uiSliderValueChanged, {
    bubbles: true, detail: { newValue: this.value }
  }));
});

Slider.prototype.recalculateFill = function recalculateFill() {
  const self = this;
  const newWidth = Math.round((this.value / this.limit) * 100);
  requestAnimationFrame(function () {
    self.fillElement.style.width = newWidth + '%';
  });
};

module.exports = Slider;
