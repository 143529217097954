'use strict';

function ComponentObjectHelper(component) {
  this.component = component;
}

ComponentObjectHelper.prototype.defineProperty = function (propName, validateFunc, afterSetFunc) {
  var value;
  Object.defineProperty(this.component, propName, {
    get: function getInternalProperty() {
      return value;
    },
    set: function setInternalProperty(newValue) {
      value = (typeof validateFunc === 'function') ? validateFunc(newValue) : newValue;
      if (typeof afterSetFunc === 'function') {
        afterSetFunc(value);
      }
    }
  });
};

ComponentObjectHelper.prototype.defineAttributeProperty = function (propName, attrName, validateFunc, afterSetFunc) {
  const element = this.component.element;
  var shadowValue = null;
  var domIsSetting = false;

  Object.defineProperty(this.component, propName, {
    get: function getAttributeProperty() {
      if (shadowValue === null) {
        shadowValue = element.getAttribute(attrName);
      }
      return shadowValue;
    },
    set: function setAttributeProperty(newValue) {
      const finalValue = (typeof validateFunc === 'function') ? validateFunc(newValue) : newValue;

      // Only update if changing
      if (finalValue === shadowValue) { return; }

      shadowValue = finalValue;

      if (!domIsSetting) {
        domIsSetting = true;
        requestAnimationFrame(function () {
          element.setAttribute(attrName, shadowValue);
          domIsSetting = false;
        });
      }

      if (typeof afterSetFunc === 'function') {
        afterSetFunc(shadowValue);
      }
    }
  });
};

ComponentObjectHelper.prototype.defineClassState = function (propName, className, validateFunc, afterSetFunc) {
  const element = this.component.element;
  var shadowValue = null;
  var domIsSetting = false;

  Object.defineProperty(this.component, propName, {
    get: function getState() {
      if (shadowValue === null) {
        shadowValue = element.classList.contains(className);
      }
      return shadowValue;
    },
    set: function setState(truth) {
      const bool = (validateFunc && typeof validateFunc === 'function') ? !!validateFunc(truth) : !!truth;

      if (shadowValue === bool) { return; }

      shadowValue = bool;

      if (!domIsSetting) {
        domIsSetting = true;
        requestAnimationFrame(function () {
          element.classList.toggle(className, shadowValue);
          domIsSetting = false;
        });
      }

      if (typeof afterSetFunc === 'function') {
        afterSetFunc(shadowValue);
      }
    }
  });
};

module.exports = ComponentObjectHelper;
