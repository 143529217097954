'use strict';

// Derived from https://github.com/bendrucker/document-ready

module.exports = function ready(callback) {
  var state = document.readyState;
  if (state === 'complete' || state === 'interactive') {
    return setTimeout(callback, 0);
  }

  document.addEventListener('DOMContentLoaded', function onLoad() {
    callback();
  });
};
