'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

var AdvancedPlaybackControls = forgeUiComponent('AdvancedPlaybackControls', {
  paused: 'is-paused',
  streamingLive: 'is-streamingLive',
  playingArchive: 'is-playingArchive',
  playingPodcast: 'is-playingPodcast'
}, function AdvancedPlaybackControlsConstructor() {
  var self = this;

  document.addEventListener(events.audioSourcePlaying, function (e) {
    if (e.detail.duration === Infinity) {
      self.setPlaybackState(AdvancedPlaybackControls.STREAMING);
    } else if (e.detail.seekable) {
      self.setPlaybackState(AdvancedPlaybackControls.PODCAST);
    } else {
      self.setPlaybackState(AdvancedPlaybackControls.ARCHIVE);
    }
  });

  document.addEventListener(events.audioSourceTimeUpdate, function (e) {
    if (e.detail.duration === Infinity) {
      self.setPlaybackState(AdvancedPlaybackControls.STREAMING);
    } else if (e.detail.seekable) {
      self.setPlaybackState(AdvancedPlaybackControls.PODCAST);
    } else {
      self.setPlaybackState(AdvancedPlaybackControls.ARCHIVE);
    }
  });
});

AdvancedPlaybackControls.prototype.setPlaybackState = function setPlaybackState(state) {
  if (state === AdvancedPlaybackControls.ARCHIVE) {
    this.playingArchive = true;
    this.playingPodcast = false;
    this.paused = false;
    this.streamingLive = false;
    return;
  }

  if (state === AdvancedPlaybackControls.PODCAST) {
    this.playingArchive = false;
    this.playingPodcast = true;
    this.paused = false;
    this.streamingLive = false;
    return;
  }

  if (state === AdvancedPlaybackControls.STREAMING) {
    this.streamingLive = true;
    this.paused = false;
    this.playingArchive = false;
    this.playingPodcast = false;
    return;
  }

  this.playingArchive = false;
  this.playingPodcast = false;
  this.streamingLive = false;
  this.paused = true;
};

AdvancedPlaybackControls.ARCHIVE = 1;
AdvancedPlaybackControls.STREAMING = 2;
AdvancedPlaybackControls.PAUSED = 3;
AdvancedPlaybackControls.PODCAST = 4;

module.exports = AdvancedPlaybackControls;
